import React from "react"
import { graphql } from "gatsby"
import useForm from "react-hook-form"
import Styled from "styled-components"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import { ButtonTrace } from "../../components/Button"

const Form = Styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	label, input, textarea, p {
		display: flex;
		justify-content: center;
		margin-top: 10px;
	}
	input:focus, textarea:focus {
		outline: ${({ theme }) =>
      theme ? `${theme.secondary} auto 5px` : "#000000 auto 5px"}
	}
	button {
		margin-top: 10px;
	}
	input, textarea {
		width: 100%;
	}
`

const Contact = ({ data }) => {
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = data => {
    console.log(data)
  }

  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={headerImg} alt={"Adam Mindi Curls Hero"} h1={"Contact BBK"}>
      <SEO title="Contact" keywords={["Body By Kickbush", "BBK"]} />
      <div className="row">
        <div className="col-md-6 mr-auto ml-auto">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <label htmlFor="fname">First name</label>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    name="fname"
                    ref={register({
                      required: "First Name is required",
                      maxLength: 80,
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.fname && <p>{errors.fname.message}</p>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="lname">Last name</label>
                </div>

                <div className="col-12">
                  <input
                    type="text"
                    name="lname"
                    ref={register({
                      required: "Last Name is required",
                      maxLength: 50,
                    })}
                  />
                </div>

                <div className="col-12">
                  {errors.lname && <p>{errors.lname.message}</p>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="email">Email</label>
                </div>

                <div className="col-12">
                  <input
                    type="email"
                    name="email"
                    ref={register({
                      required: "Email is required",
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                </div>

                <div className="col-12">
                  {errors.email && <p>{errors.email.message}</p>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="message">Message</label>
                </div>

                <div className="col-12">
                  <textarea
                    type="text"
                    name="message"
                    ref={register({
                      required: "Message is required",
                    })}
                  />
                </div>

                <div className="col-12">
                  {errors.message && <p>{errors.message.message}</p>}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <ButtonTrace onClick={handleSubmit(onSubmit)} btn={3}>
                    Submit
                  </ButtonTrace>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query contactQuery {
    header: allFile(filter: { name: { eq: "adam_mindi_1200x802" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default Contact
